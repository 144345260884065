@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets';

// http://www.colourlovers.com/palette/4322246/retro
$colour-ithoughtoflove: #343837; // I Thought Of Love
$colour-thisismyceiling: #F5F3E7; // this is my ceiling
$colour-downloadtutorial9cells: #E39E26; // Скачать учебник 9 кл
$colour-retroturquoise: #4FC2C1; // retro turquoise
$colour-clockworkorange: #EF5637; // Clockwork Orange

// swapped thisismyceiling with simple teal http://www.colourlovers.com/color/237958/simple_teal
$colour-simpleteal: #237958;

$brand-primary: $colour-simpleteal;
$brand-success: $colour-retroturquoise;
$brand-info: $colour-ithoughtoflove;
$brand-warning: $colour-downloadtutorial9cells;
$brand-danger: $colour-clockworkorange;

$jumbotron-hero-from-colour: $colour-simpleteal;

@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import './src/styles/vendor/bootstrap-custom';

@import './src/styles/components/blog';
@import './src/styles/components/social-links';
@import './src/styles/components/photo-library';
//@import './src/styles/components/btn-breadcrumb';
@import './src/styles/components/sticky-footer';
//@import './src/styles/components/fullscreen-background';
@import './src/styles/components/alerts';
@import './src/styles/components/loader';
@import './src/styles/components/jumbotron';


@font-face {
	font-family: 'robert_walter_handwritingMd';
	src: url('../fonts/robert_walter_handwriting-webfont.eot');
	src: url('../fonts/robert_walter_handwriting-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/robert_walter_handwriting-webfont.woff2') format('woff2'), url('../fonts/robert_walter_handwriting-webfont.woff') format('woff'), url('../fonts/robert_walter_handwriting-webfont.ttf') format('truetype'), url('../fonts/robert_walter_handwriting-webfont.svg#robert_walter_handwritingMd') format('svg');
	font-weight: normal;
	font-style: normal;
}

.navbar-brand {
	font-family: 'robert_walter_handwritingMd';
	padding: 6px 15px;
	font-size: 33px;
	line-height: 50px;

	&:hover {
		transform: scale(1.1);
	}
}

$cv-line-colour: $brand-primary !default;
$cv-heading-colour: $brand-info !default;
$cv-subheading-colour: $brand-success !default;


.cv {

	> .container {

		h1, h2, h3 {
			margin-top: 0;
			font-weight: bold;
			color: $cv-heading-colour;

			small {
				color: $cv-subheading-colour;
			}
		}

		> .row {
			border-top: 2px solid $cv-line-colour;

			&:first-child {
				border-top: 4px solid $cv-line-colour;
			}

			> .col-md-8 {
				padding: 20px 0;
			}

			> .col-md-4 {
				padding: 20px 0;

				&:first-child {
					border-top: 2px solid $cv-line-colour;
				}
			}

			.col-md-4 {
				&:nth-child(n+4) {
					border-top: 1px solid $cv-line-colour;
				}
			}

			.col-md-8 {
				&:nth-child(n+3) {
					border-top: 1px solid $cv-line-colour;
				}
			}
		}
	}
}
